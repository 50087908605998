import { Outlet } from "react-router-dom";
import { NewInvoice, UsedInvoice } from "../../../pages";
import adminRouteMap from "../../../routeControl/adminRouteMap";

export default function route() {
  return [
    {
      path: adminRouteMap.Device.path,
      private: true,
      name: "Invoice",
      key: adminRouteMap.Device.path,
      belongsToSidebar: true,
      icon: adminRouteMap.NEW_INVOICE.icon,
      element: <Outlet />,
      children: [
        {
          path: adminRouteMap.NEW_INVOICE.path,
          name: "New Invoice",
          key: adminRouteMap.NEW_INVOICE.path,
          private: false,
          belongsToSidebar: true,
          // icon: adminRouteMap.INVENTORY.icon,
          element: <NewInvoice />,
        },
        {
          path: adminRouteMap.USED_INVOICE.path,
          name: "Used Invoice",
          key: adminRouteMap.USED_INVOICE.path,
          private: false,
          belongsToSidebar: true,
          // icon: adminRouteMap.INVENTORY.icon,
          element: <UsedInvoice />,
        },
      ],
    },
  ];
}
