import master from "./Master";
import auth from "./Auth";
import dashboard from "./Dashboard";
import adminProfile from "./AdminAccount/index";
import addDevice from "./AddDevice/index";
import inventory from "./Inventory/index";
import invoice from "./Invoice/index";
import payment from "./Payment/index";
import booking from "./Booking/index";
import customer from "./Customer/index";
import report from "./Report/index";
import vander from "./Vander/index";

const AccessControl = {
  ...auth,
  ...dashboard,
  ...master,
  ...adminProfile,
  ...addDevice,
  ...inventory,
  ...invoice,
  ...payment,
  ...booking,
  ...customer,
  ...report,
  ...vander,
};

export default AccessControl;
