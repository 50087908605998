import React from "react";

function InvoiceFormatter({ id }) {
  return (
    <div id={id} className="salereturn-invoice bill d-none">
      <div className="invoice-header">
        <h1 className="brand">BEUNIK</h1>
        <div>
          <p className="address-phone">Used Phone Store</p>
          <p className="address">Store No. G6, 18/2 RSD China Town</p>
          <p className="address">Opp. Sindh Bakery, Sikh Mohalla</p>
          <p className="address">Main Road Kachhi Mohalla, Indore - 452007</p>
          <p className="address">Call: +91-7898944666</p>
          <p className="address">Insta: @beunik.in</p>
        </div>
        <div className="bill-tax-grp">
          <h2 className="bill-tax-head">TAX INVOICE</h2>
        </div>

        <div className="bill-gstin-head">GSTIN: 23ARMPA2295G2ZY</div>
        <div className="invoice-barcode">
          <img src="path/to/barcode.png" alt="Barcode" />
        </div>
      </div>
      <div className="border-bottom-line">
        <div className="bill-details-area">
          <div className="bill-to-head">Invoice No.:</div>
          <div className="bill-to-head">6597</div>
        </div>
        <div className="bill-details-date">
          <div className="bill-to-head">Date::</div>
          <div className="bill-to-head">03-06-2024</div>
        </div>
        <div className="bill-details-name">
          <div className="bill-to-head">Mr / Ms:</div>
          <div className="bill-to-head">Mukhtar Khan</div>
        </div>
        <div className="bill-details-contact">
          <div className="bill-to-head">Contact:</div>
          <div className="bill-to-head">9993260033</div>
        </div>
      </div>
      <div className="bill-gstin-head">PRODUCT SUMMARY</div>
      <div className="border-bottom-line">
        <div className="bill-details-device">
          <div className="bill-to-head">Device:</div>
          <div className="bill-to-head">Pre-Owned Apple iPhone 11 Pro</div>
        </div>
        <div className="bill-details-device-name">
          <div className="bill-to-head">Name:</div>
          <div className="bill-to-head">(64GB) Space Gray</div>
        </div>
        <div className="bill-details-imei">
          <div className="bill-to-head">IMEI:</div>
          <div className="bill-to-head">353242108600967/F17DG4R6N6XM</div>
        </div>
        <div className="bill-details-storeqr">
          <div className="bill-to-head">Store QR:</div>
          <div className="bill-to-head">Z3EN</div>
        </div>
        <div className="bill-details-hsn">
          <div className="bill-to-head">HSN:</div>
          <div className="bill-to-head">85177090</div>
        </div>
      </div>

      <div className="bill-gstin-head">PAYMENT SUMMARY</div>
      <div className="border-bottom-line">
        <div className="bill-details-area">
          <div className="bill-to-head">Net Payable Amount:</div>
          <div className="bill-to-head">₹29,000</div>
        </div>
        <div className="bill-details-cashrtn">
          <div className="bill-to-head">Cash-RTN:</div>
          <div className="bill-to-head">₹42,000</div>
        </div>
        <div className="invoice-qrcode">
          <img src="path/to/qrcode.png" alt="QR Code" />
        </div>
        <div className="bill-details-buyback">
          <div className="bill-to-head">Buyback Value:</div>
          <div className="bill-to-head"> ₹71,000</div>
        </div>
        <div className="bill-details-balance">
          <div className="bill-to-head">Balance:</div>
          <div className="bill-to-head">₹0</div>
        </div>
        <div className="bill-to-head">Twenty-Nine Thousand</div>
      </div>
      <div className="bill-gstin-head">TAX SUMMARY</div>
      <div className="border-bottom-line">
        <table className="table gst-table row-mb">
          <tr className="header">
            <th>SGST</th>
            <th className="gst-ml">CGST</th>
            <th className="gst-ml">Total</th>
          </tr>
          <tr>
            <td>09.00%</td>
            <td className="gst-ml">09.00%</td>
            <td className="gst-ml">2000.00</td>
          </tr>
        </table>
      </div>
      <div className="bill-gstin-head">Buy Back Value</div>
      <div className="border-bottom-line">
        <div className="buy-back-grp">
          <div className="bill-to-head">Till</div>
          <div className="bill-to-head">05-06-2024</div>
          <div className="bill-to-head">₹27,550</div>
        </div>
        <div className="buy-back-grp">
          <div className="bill-to-head">Till</div>
          <div className="bill-to-head">05-06-2024</div>
          <div className="bill-to-head">₹27,550</div>
        </div>
        <div className="buy-back-grp">
          <div className="bill-to-head">Till</div>
          <div className="bill-to-head">05-06-2024</div>
          <div className="bill-to-head">₹27,550</div>
        </div>
      </div>
      <div className="bill-gstin-head">User: Abbas</div>
      <div className="border-bottom-line" />
      <div className="bill-gstin-head">
        {"<<<<< Reasons to Buy From Us >>>>>"}
      </div>

      <div className="gst-text text-align-left">
        1. Save The Environment (Help Reduce E-Waste)
      </div>
      <div className="gst-text text-align-left">
        2. Get Our Warranty & Service Protection
      </div>
      <div className="gst-text text-align-left">
        3. Buy Back Value on All Used Phones
      </div>
      <div className="bill-gstin-head">Thank you for your purchase!</div>
      <div className="border-bottom-line" />
      <hr />
    </div>
  );
}

export default InvoiceFormatter;
