import { Outlet } from "react-router-dom";
import {
  BatteryChargingPage,
  BatteryTypePage,
  BrandPage,
  CPUPage,
  ColorPage,
  DeviceStatus,
  DisplayPage,
  ModalPage,
  NetworkPage,
  OperatingSystemPage,
  PaymentType,
  ProcessorPage,
  QCParameters,
  RAMPage,
  SIMPage,
  Sensors,
  VariantPage,
  VideoTypePage,
} from "../../../pages";
import adminRouteMap from "../../../routeControl/adminRouteMap";

export default function route() {
  return [
    {
      path: adminRouteMap.MASTER.path,
      private: true,
      name: "Master",
      key: adminRouteMap.MASTER.path,
      belongsToSidebar: true,
      icon: adminRouteMap.MASTER.icon,
      element: <Outlet />,
      children: [
        {
          path: adminRouteMap.BRAND.path,
          name: "Brand",
          key: adminRouteMap.BRAND.path,
          private: true,
          belongsToSidebar: true,
          // icon: adminRouteMap.PROCESSOR.icon,
          element: <BrandPage />,
        },
        {
          path: adminRouteMap.COLOR.path,
          name: "Color ",
          key: adminRouteMap.COLOR.path,
          private: true,
          belongsToSidebar: true,
          // icon: adminRouteMap.PROCESSOR.icon,
          element: <ColorPage />,
        },
        {
          path: adminRouteMap.CPU.path,
          name: "CPU ",
          key: adminRouteMap.CPU.path,
          private: true,
          belongsToSidebar: true,
          // icon: adminRouteMap.PROCESSOR.icon,
          element: <CPUPage />,
        },
        {
          path: adminRouteMap.DISPLAY.path,
          name: "Display",
          key: adminRouteMap.DISPLAY.path,
          private: true,
          belongsToSidebar: true,
          // icon: adminRouteMap.PROCESSOR.icon,
          element: <DisplayPage />,
        },
        {
          path: adminRouteMap.BATTERY_CHARGING.path,
          name: "Battery Charging ",
          key: adminRouteMap.BATTERY_CHARGING.path,
          private: true,
          belongsToSidebar: true,
          // icon: adminRouteMap.PROCESSOR.icon,
          element: <BatteryChargingPage />,
        },
        {
          path: adminRouteMap.BATTERY_TYPE.path,
          name: "Battery Type ",
          key: adminRouteMap.BATTERY_TYPE.path,
          private: true,
          belongsToSidebar: true,
          // icon: adminRouteMap.PROCESSOR.icon,
          element: <BatteryTypePage />,
        },
        {
          path: adminRouteMap.NETWORK.path,
          name: "Network",
          key: adminRouteMap.NETWORK.path,
          private: true,
          belongsToSidebar: true,
          // icon: adminRouteMap.PROCESSOR.icon,
          element: <NetworkPage />,
        },
        {
          path: adminRouteMap.OPERATING_SYSTEM.path,
          name: "Operating System",
          key: adminRouteMap.OPERATING_SYSTEM.path,
          private: true,
          belongsToSidebar: true,
          // icon: adminRouteMap.PROCESSOR.icon,
          element: <OperatingSystemPage />,
        },
        {
          path: adminRouteMap.PROCESSOR.path,
          name: "Processor ",
          key: adminRouteMap.PROCESSOR.path,
          private: true,
          belongsToSidebar: true,
          // icon: adminRouteMap.PROCESSOR.icon,
          element: <ProcessorPage />,
        },
        {
          path: adminRouteMap.SIM.path,
          name: "SIM ",
          key: adminRouteMap.SIM.path,
          private: true,
          belongsToSidebar: true,
          // icon: adminRouteMap.PROCESSOR.icon,
          element: <SIMPage />,
        },
        {
          path: adminRouteMap.RAM.path,
          name: "RAM ",
          key: adminRouteMap.RAM.path,
          private: true,
          belongsToSidebar: true,
          // icon: adminRouteMap.PROCESSOR.icon,
          element: <RAMPage />,
        },
        {
          path: adminRouteMap.VIDEO_TYPE.path,
          name: "Video Type ",
          key: adminRouteMap.VIDEO_TYPE.path,
          private: true,
          belongsToSidebar: true,
          // icon: adminRouteMap.PROCESSOR.icon,
          element: <VideoTypePage />,
        },

        {
          path: adminRouteMap.VARIANT.path,
          name: "Variant",
          key: adminRouteMap.VARIANT.path,
          private: true,
          belongsToSidebar: true,
          // icon: adminRouteMap.PROCESSOR.icon,
          element: <VariantPage />,
        },
        {
          path: adminRouteMap.Modal.path,
          name: "Modal",
          key: adminRouteMap.Modal.path,
          private: true,
          belongsToSidebar: true,
          // icon: adminRouteMap.PROCESSOR.icon,
          element: <ModalPage />,
        },
        {
          path: adminRouteMap.QC_PARAMETERS.path,
          name: "QC Parameters",
          key: adminRouteMap.QC_PARAMETERS.path,
          private: true,
          belongsToSidebar: true,
          // icon: adminRouteMap.PROCESSOR.icon,
          element: <QCParameters />,
        },
        {
          path: adminRouteMap.SENSOR.path,
          name: "Sensors",
          key: adminRouteMap.SENSOR.path,
          private: true,
          belongsToSidebar: true,
          // icon: adminRouteMap.PROCESSOR.icon,
          element: <Sensors />,
        },
        {
          path: adminRouteMap.DEVICE_STATUS.path,
          name: "Device Status",
          key: adminRouteMap.DEVICE_STATUS.path,
          private: true,
          belongsToSidebar: true,
          // icon: adminRouteMap.PROCESSOR.icon,
          element: <DeviceStatus />,
        },
        {
          path: adminRouteMap.PAYMENT_TYPE.path,
          name: "Payment Type",
          key: adminRouteMap.PAYMENT_TYPE.path,
          private: true,
          belongsToSidebar: true,
          // icon: adminRouteMap.PROCESSOR.icon,
          element: <PaymentType />,
        },
      ],
    },
  ];
}
