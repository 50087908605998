import * as yup from "yup";

export default function validation() {
  return yup.object().shape({
    name: yup.string().required("Please enter name"),
    // email: yup.string().required("Please enter email"),
    code: yup.string().required("Please enter vander code"),
    mobile: yup.string().required("Please enter mobile number"),
    // tax_number: yup.string().required("Please enter tax number"),
  });
}
