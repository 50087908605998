import { Outlet } from "react-router-dom";
import { Customer, VanderList } from "../../../pages";
import adminRouteMap from "../../../routeControl/adminRouteMap";

export default function route() {
  return [
    {
      path: adminRouteMap.USERS.path,
      private: true,
      name: "Users",
      key: adminRouteMap.USERS.path,
      belongsToSidebar: true,
      icon: adminRouteMap.CUSTOMER.icon,
      element: <Outlet />,
      children: [
        {
          path: adminRouteMap.VANDER.path,
          name: "Vander",
          key: adminRouteMap.VANDER.path,
          private: false,
          belongsToSidebar: true,
          //   icon: adminRouteMap.VANDER.icon,
          element: <VanderList />,
        },
        {
          path: adminRouteMap.CUSTOMER.path,
          name: "Customer",
          key: adminRouteMap.CUSTOMER.path,
          private: false,
          belongsToSidebar: true,
          //   icon: adminRouteMap.CUSTOMER.icon,
          element: <Customer />,
        },
      ],
    },
  ];
}
