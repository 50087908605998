// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  MASTER: {
    path: `${baseRoutes.adminBaseRoutes}`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-view-x3" />
      </span>
    ),
  },
  PROCESSOR: {
    path: `${baseRoutes.adminBaseRoutes}processor`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users" />
      </span>
    ),
  },
  CPU: {
    path: `${baseRoutes.adminBaseRoutes}cpu`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users" />
      </span>
    ),
  },
  SIM: {
    path: `${baseRoutes.adminBaseRoutes}sim`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users" />
      </span>
    ),
  },
  COLOR: {
    path: `${baseRoutes.adminBaseRoutes}color`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users" />
      </span>
    ),
  },
  BATTERY_TYPE: {
    path: `${baseRoutes.adminBaseRoutes}battery-type`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users" />
      </span>
    ),
  },
  BATTERY_CHARGING: {
    path: `${baseRoutes.adminBaseRoutes}battery-charging`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users" />
      </span>
    ),
  },
  DISPLAY: {
    path: `${baseRoutes.adminBaseRoutes}display`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users" />
      </span>
    ),
  },
  VIDEO_TYPE: {
    path: `${baseRoutes.adminBaseRoutes}video-type`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users" />
      </span>
    ),
  },
  NETWORK: {
    path: `${baseRoutes.adminBaseRoutes}network`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users" />
      </span>
    ),
  },
  OPERATING_SYSTEM: {
    path: `${baseRoutes.adminBaseRoutes}operating-system`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users" />
      </span>
    ),
  },
  Modal: {
    path: `${baseRoutes.adminBaseRoutes}modal`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users" />
      </span>
    ),
  },
  VARIANT: {
    path: `${baseRoutes.adminBaseRoutes}varinat`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users" />
      </span>
    ),
  },
  BRAND: {
    path: `${baseRoutes.adminBaseRoutes}brand`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users" />
      </span>
    ),
  },
  RAM: {
    path: `${baseRoutes.adminBaseRoutes}ram`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users" />
      </span>
    ),
  },
  QC_PARAMETERS: {
    path: `${baseRoutes.adminBaseRoutes}qcparameter`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users" />
      </span>
    ),
  },
  SENSOR: {
    path: `${baseRoutes.adminBaseRoutes}sensor`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users" />
      </span>
    ),
  },
  DEVICE_STATUS: {
    path: `${baseRoutes.adminBaseRoutes}device-status`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users" />
      </span>
    ),
  },
  PAYMENT_TYPE: {
    path: `${baseRoutes.adminBaseRoutes}payment-type`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-users" />
      </span>
    ),
  },
};

export default accessRoute;
