import { Outlet } from "react-router-dom";
import {
  Inventory,
  InventoryDetails,
  QCForm,
  UsedInventory,
} from "../../../pages";
import adminRouteMap from "../../../routeControl/adminRouteMap";

export default function route() {
  return [
    {
      path: adminRouteMap.Device.path,
      private: true,
      name: "Manage Inventory",
      key: adminRouteMap.Device.path,
      belongsToSidebar: true,
      icon: adminRouteMap.INVENTORY.icon,
      element: <Outlet />,
      children: [
        {
          path: adminRouteMap.INVENTORY.path,
          name: "New Inventory",
          key: adminRouteMap.INVENTORY.path,
          private: false,
          belongsToSidebar: true,
          // icon: adminRouteMap.INVENTORY.icon,
          element: <Inventory />,
        },
        {
          path: adminRouteMap.USED_INVENTORY.path,
          name: "Used Inventory",
          key: adminRouteMap.USED_INVENTORY.path,
          private: false,
          belongsToSidebar: true,
          // icon: adminRouteMap.INVENTORY.icon,
          element: <UsedInventory />,
        },
        {
          path: adminRouteMap.INVENTORY_Details.path,
          name: "Manage Inventory Details",
          key: adminRouteMap.INVENTORY_Details.path,
          private: false,
          belongsToSidebar: false,
          icon: adminRouteMap.INVENTORY_Details.icon,
          element: <InventoryDetails />,
        },
        {
          path: `${adminRouteMap.CHECK_QC.path}/:id`,
          name: "QC Form",
          key: `${adminRouteMap.CHECK_QC.path}/:id`,
          private: false,
          belongsToSidebar: false,
          icon: adminRouteMap.CHECK_QC.icon,
          element: <QCForm />,
        },
      ],
    },
  ];
}
