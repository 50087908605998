import { Report } from "../../../pages";
import adminRouteMap from "../../../routeControl/adminRouteMap";

export default function route() {
  return [
    {
      path: adminRouteMap.REPORT.path,
      name: "Report",
      key: adminRouteMap.REPORT.path,
      private: false,
      belongsToSidebar: true,
      icon: adminRouteMap.REPORT.icon,
      element: <Report />,
    },
  ];
}
