// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  Device: {
    path: `${baseRoutes.adminBaseRoutes}`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-mobile" />
      </span>
    ),
  },
  ADD_DEVICE: {
    path: `${baseRoutes.adminBaseRoutes}add-device`,
  },
  EDIT_DEVICE: {
    path: `${baseRoutes.adminBaseRoutes}edit-device`,
  },
  DEVICE_LIST: {
    path: `${baseRoutes.adminBaseRoutes}device-list`,
  },
};

export default accessRoute;
