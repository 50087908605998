// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  INVENTORY: {
    path: `${baseRoutes.adminBaseRoutes}inventory`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-box" />
      </span>
    ),
  },
  INVENTORY_Details: {
    path: `${baseRoutes.adminBaseRoutes}inventory-details`,
  },
  USED_INVENTORY: {
    path: `${baseRoutes.adminBaseRoutes}used-inventory`,
  },
  CHECK_QC: {
    path: `${baseRoutes.adminBaseRoutes}qc-form`,
  },
};

export default accessRoute;
