import { Formik, Form } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { AntTextArea, Input as TextInput } from "../../../Antd";
// import validation from "./validation";
import { CommonButton } from "../../../UiElement";
import validation from "./validation";

function VanderForm({ onSubmit, loading, rowData }) {
  const initialValues = {
    name: rowData?.name || "",
    email: rowData?.email || "",
    code: rowData?.code || "",
    mobile: rowData?.mobile || "",
    alt_mobile: rowData?.alt_mobile || "",
    tax_number: rowData?.tax_number || "",
    address: rowData?.address || "",
  };

  return (
    <>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={validation()}
        onSubmit={onSubmit}
      >
        {(props) => {
          return (
            <Form>
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="category-name">
                      Vander Name
                    </label>
                    <div className="form-control-wrap">
                      <TextInput
                        className="form-control"
                        name="name"
                        disabled={false}
                        variant="standard"
                        type="text"
                        placeholder="Enter vander name"
                        setFieldValue={props.handleChange}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="category-name">
                      Vander Code
                    </label>
                    <div className="form-control-wrap">
                      <TextInput
                        className="form-control"
                        name="code"
                        disabled={false}
                        variant="standard"
                        type="text"
                        placeholder="Enter vander code"
                        setFieldValue={props.handleChange}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="category-name">
                      Email
                    </label>
                    <div className="form-control-wrap">
                      <TextInput
                        className="form-control"
                        name="email"
                        disabled={false}
                        variant="standard"
                        type="text"
                        placeholder="Enter email"
                        setFieldValue={props.handleChange}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="category-name">
                      Mobile Number
                    </label>
                    <div className="form-control-wrap">
                      <TextInput
                        className="form-control"
                        name="mobile"
                        disabled={false}
                        variant="standard"
                        type="text"
                        placeholder="Enter mobile number"
                        setFieldValue={props.handleChange}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="category-name">
                      Alternator Mobile Number
                    </label>
                    <div className="form-control-wrap">
                      <TextInput
                        className="form-control"
                        name="alt_mobile"
                        disabled={false}
                        variant="standard"
                        type="text"
                        placeholder="Enter alternator mobile number"
                        setFieldValue={props.handleChange}
                      />
                    </div>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="category-name">
                      Tax Number
                    </label>
                    <div className="form-control-wrap">
                      <TextInput
                        className="form-control"
                        name="tax_number"
                        disabled={false}
                        variant="standard"
                        type="text"
                        placeholder="Enter tax number"
                        setFieldValue={props.handleChange}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label className="form-label" htmlFor="category-name">
                      Address
                    </label>
                    <div className="form-control-wrap">
                      <AntTextArea
                        className="form-control"
                        name="address"
                        disabled={false}
                        variant="standard"
                        type="text"
                        placeholder="Enter address"
                        setFieldValue={props.handleChange}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="form-group text-center mt-3">
                <CommonButton
                  type="submit"
                  htmlType="submit"
                  className="btn btn-primary ripple-effect"
                  loading={loading}
                >
                  {rowData?.id ? "Update" : "Submit"}
                </CommonButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default VanderForm;
