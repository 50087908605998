// import { DesktopOutlined } from "@ant-design/icons";

import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  NEW_INVOICE: {
    path: `${baseRoutes.adminBaseRoutes}invoice`,
    icon: (
      <span className="nk-menu-icon">
        <em className="icon ni ni-box" />
      </span>
    ),
  },
  USED_INVOICE: {
    path: `${baseRoutes.adminBaseRoutes}used-invoice`,
  },
};

export default accessRoute;
