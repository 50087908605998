import React from "react";

export const AddProcesserForm = React.lazy(() =>
  import("./AddProcesser/index.form")
);
export const AddSimForm = React.lazy(() => import("./AddSim/index.form"));
export const AddColorForm = React.lazy(() => import("./AddColor/index.form"));
export const AddCPUForm = React.lazy(() => import("./AddCPU/index.form"));
export const AddBatteryTypeForm = React.lazy(() =>
  import("./AddBatteryType/index.form")
);
export const AddCommonForm = React.lazy(() =>
  import("./AddCommonForm/index.form")
);
