import React from "react";

export const AdminLogin = React.lazy(() => import("./Login/index.page"));
export const AdminDashboard = React.lazy(() =>
  import("./Dashboard/index.page")
);
export const ProcessorPage = React.lazy(() =>
  import("./Master/Processer/index.page")
);
export const AdminProfile = React.lazy(() =>
  import("./AdminAccount/AdminProfile/index.page")
);
export const AdminChangePassword = React.lazy(() =>
  import("./AdminAccount/AdminChangePassword/index.page")
);
export const CPUPage = React.lazy(() => import("./Master/CPU/index.page"));
export const SIMPage = React.lazy(() => import("./Master/SIM/index.page"));
export const ColorPage = React.lazy(() => import("./Master/Color/index.page"));
export const BatteryTypePage = React.lazy(() =>
  import("./Master/BatteryType/index.page")
);
export const BatteryChargingPage = React.lazy(() =>
  import("./Master/BatteryCharging/index.page")
);
export const DisplayPage = React.lazy(() =>
  import("./Master/Display/index.page")
);
export const VideoTypePage = React.lazy(() =>
  import("./Master/VideoType/index.page")
);
export const OperatingSystemPage = React.lazy(() =>
  import("./Master/OperatingSystem/index.page")
);
export const ModalPage = React.lazy(() => import("./Master/Modal/index.page"));
export const VariantPage = React.lazy(() =>
  import("./Master/Variant/index.page")
);
export const BrandPage = React.lazy(() => import("./Master/Brand/index.page"));
export const NetworkPage = React.lazy(() =>
  import("./Master/Network/index.page")
);
export const RAMPage = React.lazy(() => import("./Master/Ram/index.page"));
export const AddDevice = React.lazy(() => import("./AddDevice/index.page"));
export const Inventory = React.lazy(() => import("./Inventory/index.page"));
export const InventoryDetails = React.lazy(() =>
  import("./Inventory/details.index")
);
export const DeviceList = React.lazy(() => import("./AddDevice/deviceList"));
export const UsedInventory = React.lazy(() =>
  import("./Inventory/UsedInventory/index")
);
export const NewInvoice = React.lazy(() =>
  import("./Invoice/NewInvoice/index")
);
export const UsedInvoice = React.lazy(() =>
  import("./Invoice/UsedInvoice/index")
);
export const Payment = React.lazy(() => import("./Payment/index.page"));
export const Booking = React.lazy(() => import("./Booking/index.page"));
export const Customer = React.lazy(() => import("./Customer/index.page"));
export const Report = React.lazy(() => import("./Reports/index.page"));
export const QCParameters = React.lazy(() =>
  import("./Master/QCParameters/index.page")
);
export const Sensors = React.lazy(() => import("./Master/Sensor/index.page"));
export const VanderList = React.lazy(() => import("./Vander/index.page"));
export const QCForm = React.lazy(() => import("./Inventory/QCTable/index"));
export const DeviceStatus = React.lazy(() =>
  import("./Master/DeviceStatus/index.page")
);
export const PaymentType = React.lazy(() =>
  import("./Master/PaymentType/index.page")
);
