import { Outlet } from "react-router-dom";
import { AddDevice, DeviceList } from "../../../pages";
import adminRouteMap from "../../../routeControl/adminRouteMap";

export default function route() {
  return [
    {
      path: adminRouteMap.Device.path,
      private: true,
      name: "Device",
      key: adminRouteMap.Device.path,
      belongsToSidebar: true,
      icon: adminRouteMap.Device.icon,
      element: <Outlet />,
      children: [
        {
          path: adminRouteMap.ADD_DEVICE.path,
          name: "Add Device",
          key: adminRouteMap.ADD_DEVICE.path,
          private: false,
          belongsToSidebar: true,
          icon: adminRouteMap.ADD_DEVICE.icon,
          element: <AddDevice />,
        },
        {
          path: adminRouteMap.EDIT_DEVICE.path,
          name: "Edit Device",
          key: adminRouteMap.EDIT_DEVICE.path,
          private: false,
          belongsToSidebar: false,
          icon: adminRouteMap.EDIT_DEVICE.icon,
          element: <AddDevice />,
        },
        {
          path: adminRouteMap.DEVICE_LIST.path,
          name: "Device List",
          key: adminRouteMap.DEVICE_LIST.path,
          private: false,
          belongsToSidebar: true,
          icon: adminRouteMap.DEVICE_LIST.icon,
          element: <DeviceList />,
        },
      ],
    },
  ];
}
